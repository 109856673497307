import { policyList } from "@/enums/permissions";
import { ProductCategory } from "@/interfaces/productCategoy";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { ActionSubheader, Callback, TableAction } from "helix-vue-components";
import { productCategoryService } from "./productCategory.service";

class ProductCategoryActionService {
  public getGeneralActions(
    csvCallback: () => void,
    onEdit: (product: ProductCategory) => void,
    filtersCallback: () => void
  ): ActionSubheader[] {
    return [
      {
        icon: "fal fa-file-csv",
        action: csvCallback
      },
      {
        icon: "fal fa-plus",
        id: "btn_create_prodcat",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.createProductsCategories
          ),
        action: (product: ProductCategory) => onEdit(product)
      },
      {
        icon: "fal fa-search",
        id: "btn_search_prodcat",
        otherComponent: {
          name: "TableSearchComponent"
        }
      },
      {
        icon: "fal fa-filter",
        action: filtersCallback
      }
    ];
  }

  public getRowActions(
    onEdit: (product: ProductCategory) => void,
    onSuccess: () => void
  ): TableAction[] {
    return [
      {
        icon: "fal fa-pen",
        id: "btn_edit_prodcat",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.modifyProductsCategories
          ),
        action: (product: ProductCategory) => onEdit(product)
      },
      {
        icon: "",
        switch: "is_active",
        id: "btn_delete_prodcat",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableProductsCategories
          ),
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("product_category_toggle_confirm").toString(),
          modalSuccessText: i18n.t("yes").toString(),
          modalIdSuccess: "btn_delete_yes",
          modalSuccessAction: ({ item, unselectModal, modelValue }) => {
            productCategoryService
              .toggleProductCategory(item, modelValue)
              .then(() => unselectModal())
              .finally(() => onSuccess());
          },
          modalCancelText: "No",
          modalIdCancel: "btn_delete_no"
        }
      }
    ];
  }

  public getMultiActions(onSuccess: () => void): TableAction[] {
    return [
      {
        icon: "fal fa-eye",
        action(productCategories: ProductCategory[]) {
          productCategoryService
            .enableMultiple(productCategories.map(pc => pc.id))
            .then(() => onSuccess());
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableProductsCategories
          )
      },
      {
        icon: "fal fa-eye-slash",
        action(productCategories: ProductCategory[]) {
          productCategoryService
            .disableMultiple(productCategories.map(pc => pc.id))
            .then(() => onSuccess());
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableProductsCategories
          )
      }
    ];
  }
}

export const productCategoryActionService = new ProductCategoryActionService();

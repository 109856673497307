import ProductCategoryFormComponent from "@/components/inventory/productCategory/productCategoryForm/ProductCategoryForm.component";
import ChoiceModal from "@/components/locations/importExportWizard/choiceModal/ChoiceModal.component";
import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import { HttpQuery } from "@/interfaces/httpQuery";
import { ProductCategory } from "@/interfaces/productCategoy";
import { pusherEvents } from "@/internal";
import { ProductCategoryTableMetadata } from "@/metadata/productCategory";
import { INITIAL_QUERY_STATE } from "@/services/http.service";
import { messagesService } from "@/services/messages.service";
import { productCategoryActionService } from "@/services/productCategory.action.service";
import { productCategoryService } from "@/services/productCategory.service";
import { PageNavAction } from "@/types/types";
import {
  ActionSubheader,
  BooleanCheck,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./ProductCategoryList.template.vue";
/**
 * component to manage the product category list
 */
@Component({
  mixins: [Template],
  components: {
    TableComponent,
    ProductCategoryFormComponent
  },
  inject: ["$changes"]
})
@ResetService(productCategoryService)
export default class ProductCategoryListComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  /**
   * Edition modal data.
   */
  public model: ProductCategory | null = null;

  /**
   * Products  of product category list component
   */
  public products: ProductCategory[] = [];
  public enableMultiActions = true;
  public currentFilter: { active?: boolean } | null = null;
  /**
   * Pagination data.
   */
  public pagination: TablePagination | null = null;

  /**
   * Loading state.
   */
  public loading: boolean = true;

  /**
   * Headers data for the table
   */
  public headers: TableHeader[] = ProductCategoryTableMetadata;
  public showFilters = false;
  public activeFilter = false;
  /**
   * General actions for subheader component.
   */
  public generalActions: ActionSubheader[] = productCategoryActionService.getGeneralActions(
    this.openCsvModal,
    this.editModal,
    this.filtersCallback
  );

  /**
   * Row actions data for the table.
   */
  public rowActions: TableAction[] = productCategoryActionService.getRowActions(
    this.editModal,
    this.triggerSearch
  );

  /**
   * Multi actions options.
   */
  protected multiActions: TableAction[] = productCategoryActionService.getMultiActions(
    this.triggerSearch
  );

  /**
   * Data to set queries for each API request.
   */
  protected query: HttpQuery = {
    ...INITIAL_QUERY_STATE
  };

  /**
   * Table colors.
   */
  protected colors = ["white", "white", "white"];

  /**
   * Opens CSV modal.
   */
  public openCsvModal() {
    this.$modals.load(
      ChoiceModal,
      {
        size: "fit",
        positionY: "top"
      },
      { csvType: "product-categories" }
    );
  }

  public filtersCallback() {
    this.showFilters = !this.showFilters;
    if (!this.showFilters) {
      this.triggerSearch({ "q[is_active]": 1 });
    }
  }

  /**
   * Triggers search for qualified/non qualified products.
   * @param header: TableHader
   */
  public onProductCategoryFilter(active: boolean) {
    this.activeFilter = active;
    const param = active
      ? { "q[is_active]": 1 }
      : { "q[is_active_is_false]": 1 };
    this.triggerSearch(param);
  }

  public editModal(product?: ProductCategory) {
    this.model = product ? { ...product } : null;
    this.$modals
      .load(
        ProductCategoryFormComponent,
        {
          size: "normal",
          positionY: "top"
        },
        {
          model: this.model
        }
      )
      .then(() => this.triggerSearch())
      .catch(() => {
        // Just close modal
      });
  }

  /**
   * Updates requests on table page change event.
   * @param pagination: TablePagination
   */
  public onChangePage(pagination: TablePagination) {
    this.triggerSearch({
      ...this.query,
      page: pagination.currentPage,
      per_page: pagination.itemsPerPage
    });
  }

  /**
   * Updates requests on column name click to sort.
   * @param header: TableHader
   */
  public filter(header: TableHeader) {
    this.triggerSearch({
      ...this.query,
      sort: productCategoryService.sortQuery(header)
    });
  }

  /**
   * Updates table data based on search component.
   * @param term: string
   */
  public async onSearch(term: string) {
    this.triggerSearch({
      ...INITIAL_QUERY_STATE,
      "q[name_contains]": term
    });
  }
  /**
   * Takes action on serch bar close event.
   * @param update: boolean
   */
  public onClose(update: boolean) {
    if (update) {
      this.triggerSearch(INITIAL_QUERY_STATE);
    }
  }

  private mounted() {
    this.setPageNav({
      title: "label.product_category",
      rightActions: {
        generalActions: () => this.generalActions,
        onSearch: this.onSearch,
        onClose: this.onClose
      }
    });
    this.$changes.watch(pusherEvents.productCategoryTouched, async () => {
      this.products = await productCategoryService.get();
    });
    this.triggerSearch(this.query);
    this.enableMultiActions = this.hasPermission(
      policyList.enableDisableProductsCategories
    );
  }

  private async triggerSearch(query?: HttpQuery) {
    this.loading = true;
    this.query = query || this.query;
    try {
      if (
        this.pagination &&
        this.pagination.from === this.pagination.to &&
        this.query.page
      ) {
        this.query.page! -= 1;
      }
      this.pagination = await productCategoryService.getPagination();
      this.products = await productCategoryService.get(this.query);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      this.loading = false;
    }
  }
}

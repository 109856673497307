import { ProductCategory } from "@/interfaces/productCategoy";
import { ProductCategoryFormMetadata } from "@/metadata/productCategory";
import { productCategoryService } from "@/services/productCategory.service";
import {
  DynamicFormComponent,
  FormField,
  FormModel,
  FormResponse
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./ProductCategoryForm.template.vue";

@Component({
  mixins: [Template],
  components: {
    DynamicFormComponent
  }
})
export default class ProductCategoryFormComponent extends Vue {
  /**
   * Model form.
   */
  @Prop({ default: null }) public model!: FormModel | null;

  /**
   * Clone of model to pass to dynamic form.
   */
  public formModel: FormModel | null = null;

  /**
   * Form configuration.
   */
  public fieldsConfig: FormField[] = ProductCategoryFormMetadata;

  /**
   * Form ref.
   */
  public form!: DynamicFormComponent;

  /**
   * Flag to disable buttons on save.
   */
  public loading = false;

  /**
   * Emits saved/updated form data.
   */
  public async save() {
    this.loading = true;
    const resp: FormResponse = await this.form.submit();
    if (resp.valid) {
      if (this.valueChangedOrIsNew(resp)) {
        await productCategoryService.save(resp.currentModel as ProductCategory);
        this.$emit("resolve");
      } else {
        this.$emit("reject");
      }
    }
    this.loading = false;
  }

  public cancel() {
    this.$emit("reject");
  }

  public mounted() {
    this.form = this.$refs["product-category-form"] as DynamicFormComponent;
    this.formModel = this.model ? cloneDeep(this.model) : null;
  }

  private valueChangedOrIsNew(resp: FormResponse) {
    return (
      (this.model && resp.currentModel!.name !== this.model.name) ||
      (resp.currentModel && !this.model)
    );
  }
}

import { i18n } from "@/plugins/i18n";
import {
  FormField,
  FormFieldTypes,
  TableFieldType,
  TableHeader
} from "helix-vue-components";

export const ProductCategoryTableMetadata: TableHeader[] = [
  {
    value: "name",
    label: String(i18n.t("category_name")),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  }
];

export const ProductCategoryFormMetadata: FormField[] = [
  {
    label: i18n.t("category_name"),
    name: "name",
    component: FormFieldTypes.textField,
    options: {
      validationRules: ["required"],
      row: 1,
      flex: 4
    }
  }
];
